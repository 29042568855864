'use client';

import React from 'react';
import style from './style.module.css';
import { TagQs } from './defaultRecommended';
import QuestionsBox from './questionsBox';

interface Props {
  recommendedQs: TagQs[];
}

export default function RecommendedQsDesktop({ recommendedQs }: Props) {
  return (
    <div className={style.qsContainer}>
      {recommendedQs.map((tagQs) => (
        <QuestionsBox
          tag={tagQs.tag}
          questions={tagQs.questions}
          key={tagQs.tag}
        />
      ))}
    </div>
  );
}
