import { useState, useEffect } from 'react';

const MOBILE_WIDTH_THRESHOLD = 768;

const isBrowser = typeof window !== 'undefined';

const getDeviceType = () => {
  if (!isBrowser) return 'desktop';
  const isMobileUserAgent =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  const isMobileWidth = window.innerWidth < MOBILE_WIDTH_THRESHOLD;
  return isMobileUserAgent && isMobileWidth ? 'mobile' : 'desktop';
};

const getOrientation = () => {
  if (!isBrowser) return 'landscape';
  return window.matchMedia('(orientation: portrait)').matches
    ? 'portrait'
    : 'landscape';
};

const useDeviceAndOrientation = () => {
  const [device, setDevice] = useState(getDeviceType());
  const [orientation, setOrientation] = useState(getOrientation());

  useEffect(() => {
    if (!isBrowser) return;
    const handleResizeChange = () => {
      setDevice(getDeviceType());
    };
    const handleOrientationChange = () => {
      setOrientation(getOrientation());
    };

    window.addEventListener('resize', handleResizeChange);
    window.addEventListener('orientationchange', handleOrientationChange);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResizeChange);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []);

  return { device, orientation };
};

export default useDeviceAndOrientation;
