'use client';

import Image from 'next/image';
import { Roboto } from 'next/font/google';

import RecommendedQs from 'components/recommendedQs';
import {
  defaultRecommendedDev,
  defaultRecommendedProd,
} from 'components/recommendedQs/defaultRecommended';
import { isDevOrLocalhost } from 'utils/helpers';

import cleeAILogoV2 from 'public/images/icons/cleeaiLogoV2.svg?url';
import trustpilotRating from 'public/images/icons/trustpilotRating.svg?url';
import trustpilotStar from 'public/images/icons/trustpilotStar.svg?url';

import style from './style.module.css';
import UnauthSearchBox from 'components/UnauthSearchBox';
import Link from 'next/link';
import classNames from 'classnames';
import AnnouncementBanner from 'components/AnnouncementBanner';
import ErrorMessage from 'components/ErrorMessage';
import { useSearchProvider } from 'providers/SearchProvider';
import { MAX_ANONYMOUS_QUESTIONS } from 'providers/qnaAPI';

const roboto = Roboto({
  weight: ['400'],
  style: ['normal'],
  subsets: ['latin'],
});

export function UnauthHome() {
  const searchProvider = useSearchProvider();

  const searchBoxErrorMsg =
    searchProvider?.anonymousSearchForm.getErrorMessage(false);

  const defaultRecommended = isDevOrLocalhost()
    ? defaultRecommendedDev
    : defaultRecommendedProd;

  return (
    <div className={style.unAuthHomeContainer}>
      <div className={style.bannerContainer}>
        <AnnouncementBanner />
      </div>
      <div className={style.innerContainer}>
        <div className={style.centered}>
          <Image className={style.logo} src={cleeAILogoV2} alt="Clee AI logo" />
        </div>
        <h1 className={style.heading}>
          Ask anything. <span>Trust</span> everything.
        </h1>
        <div className={style.centered}>
          <UnauthSearchBox
            className={style.searchBoxContainer}
          ></UnauthSearchBox>
          {Boolean(searchBoxErrorMsg) ? (
            <ErrorMessage className={style.unauthSearchBoxErrorMsg}>
              {searchBoxErrorMsg}
            </ErrorMessage>
          ) : (
            <p className={style.allowedQsNum}>
              Ask up to {MAX_ANONYMOUS_QUESTIONS} question
              {MAX_ANONYMOUS_QUESTIONS > 1 ? 's' : ''} before signing up.{' '}
              <span
                className={style.signupLink}
                onClick={() =>
                  searchProvider?.anonymousSearch.setOpenModal('signup')
                }
              >
                Sign up to ask unlimited questions.
              </span>
            </p>
          )}
        </div>
        <div className={style.recommendedQsContainer}>
          <RecommendedQs recommendedQs={defaultRecommended} />
        </div>
      </div>
      <Link
        href={'https://www.trustpilot.com/review/cleeai.com'}
        className={classNames(style.ratingContainer, roboto.className)}
        target="_blank"
      >
        <p className={style.ratingText}>Trusted</p>
        <Image alt="Trustpilot rating" src={trustpilotRating} />
        <div className={style.ratingRight}>
          <Image alt="Trustpilot logo star" src={trustpilotStar} />
          <p className={style.ratingText}>Trustpilot</p>
        </div>
      </Link>
    </div>
  );
}
