'use client';

import React from 'react';
import style from './style.module.css';
import useDeviceAndOrientation from 'hooks/useDeviceAndOrientation';
import { TagQs } from './defaultRecommended';
import RecommendedQsMobile from './mobile';
import RecommendedQsDesktop from './desktop';

interface Props {
  recommendedQs: TagQs[];
  isLoading?: boolean;
}

export default function RecommendedQs({ recommendedQs, isLoading }: Props) {
  const isMobile = useDeviceAndOrientation()['device'] == 'mobile';

  return (
    <div className={style.qsContainer}>
      {isMobile ? (
        <RecommendedQsMobile
          recommendedQs={recommendedQs}
          isLoading={isLoading}
        />
      ) : (
        <RecommendedQsDesktop recommendedQs={recommendedQs} />
      )}
    </div>
  );
}
