'use client';

import AnnouncementBanner from 'components/AnnouncementBanner';
import ErrorMessage from 'components/ErrorMessage';
import ProUpgradeModal from 'components/ModalContent/ProUpgrade';
import SearchBox from 'components/SearchBox';
import RecommendedQs from 'components/recommendedQs';
import {
  defaultRecommendedDev,
  defaultRecommendedProd,
} from 'components/recommendedQs/defaultRecommended';
import useAuth from 'hooks/useAuth';
import { analytics } from 'lib/analytics';
import { useModalProvider } from 'providers/ModalProvider';
import { useSearchProvider } from 'providers/SearchProvider';
import { useEffect } from 'react';
import { isDevOrLocalhost } from 'utils/helpers';
import st from './style.module.css';

export function AuthHome() {
  const [user] = useAuth();
  const searchProvider = useSearchProvider();
  const modal = useModalProvider();

  const defaultRecommended = isDevOrLocalhost()
    ? defaultRecommendedDev
    : defaultRecommendedProd;

  useEffect(() => {
    if (searchProvider?.search.getIsPaywallModalOpened(false)) {
      modal.open(<ProUpgradeModal />, {
        onClose: () => {
          analytics.generic(user, 'Dismissed Upgrade Banner');
          searchProvider?.search.setIsPaywallModalOpened(false);
        },
      });
    }
  }, [searchProvider?.search.getIsPaywallModalOpened(false)]);

  return (
    <div className={st.authHomeContainer}>
      <div className={st.authContainerScrollable}>
        <div className={st.authBannerContainer}>
          <AnnouncementBanner />
        </div>
        <div className={st.headingContainer}>
          <h1 className={st.heading}>Ask anything.</h1>
          <h1 className={st.heading}>
            <span>Trust</span> everything.
          </h1>
        </div>
        <div className={st.centeredSearchContainer}>
          <div
            className={st.searchBoxContainer}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => {
              event.preventDefault;
              event.stopPropagation();
            }}
          >
            <SearchBox
              onSubmitCB={async () => {
                await searchProvider?.searchForm.onSubmit();
                analytics.questionAsked(
                  user,
                  searchProvider?.searchForm.getSearchText(),
                );
              }}
              onChangeCB={(ev) => searchProvider?.searchForm.onTextChanged(ev)}
              value={searchProvider?.searchForm.getSearchText() ?? ''}
            />
          </div>
          {Boolean(searchProvider?.searchForm.getErrorMessage()) && (
            <ErrorMessage className={st.errorMessage}>
              {searchProvider?.searchForm.getErrorMessage()}
            </ErrorMessage>
          )}
        </div>
        <div className={st.recommendedQsContainer}>
          <RecommendedQs recommendedQs={defaultRecommended} />
        </div>
      </div>
    </div>
  );
}
