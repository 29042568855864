import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import style from './style.module.css';
import { Q } from './defaultRecommended';
import { analytics } from 'lib/analytics';
import { useSearchProvider } from 'providers/SearchProvider';
import { ANON_ID_FOR_SUGGESTED_QUERIES } from './defaultRecommended';
import useAuth from 'hooks/useAuth';

interface Props {
  tag: string;
  questions: Q[];
}

export default function QuestionsBox({ tag, questions }: Props) {
  const searchProvider = useSearchProvider();
  const [user] = useAuth();

  const onClickHandler = (question: Q) => {
    analytics.clickedSuggestedQuery(
      user,
      question.searchId,
      question.question,
      tag,
    );
    if (user) {
      // if user is logged in, create a search in user's account
      searchProvider?.search.create(question.question);
    } else {
      // if user is not logged in, create an anonymous search
      searchProvider?.anonymousSearch.returnCachedOrCreate(
        question.searchId,
        question.question,
        ANON_ID_FOR_SUGGESTED_QUERIES,
        // SUGGESTED_QUERIES_TTL_IN_DAYS,
      );
    }
  };

  return (
    <div className={style.questionsBox}>
      <p className={style.subHeadingQA}>{tag || <Skeleton count={1} />}</p>
      {questions.map((q) => (
        <button
          className={style.questionBtn}
          key={q.searchId}
          onClick={() => onClickHandler(q)}
        >
          <span className={style.questionBtnEmoji}>{q.emoji}</span>
          <p className={style.questionBtnText}>
            {q.question || <Skeleton count={2} />}
          </p>
        </button>
      ))}
    </div>
  );
}
